const patternFn = (pattern: string): string => `${pattern}`;
export const authErrorConfig = {
  errors: {
    useValue: {
      required: 'validator.required',
      email: 'validator.email',
      minlength: (config: { requiredLength: number; actualLength: number }) =>
        `validator.min_length_${config.requiredLength}`,
      maxlength: (config: { requiredLength: number; actualLength: number }) =>
        `validator.max_length_${config.requiredLength}`,

      wrong: 'validator.wrong',
      incorrectOtp: 'validator.incorrect_otp',
      codeExpired: 'validator.code_expired',
      usernameNotFoundInvalid: patternFn,
      emailNotFoundInvalid: patternFn,
      usernameInvalid: patternFn,
      emailInvalid: patternFn,
      phoneInvalid: patternFn,

      usernamePattern: patternFn,
      usernamePassportPattern: patternFn,
      usernameComboPattern: patternFn,
      emailPattern: patternFn,

      symbolsPattern: patternFn,
      cityPattern: patternFn,
      phonePattern: patternFn,
    },
  },
};
